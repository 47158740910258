@import 'Styles/includes';

.Billboard {
    $root: &;

    margin-top: 48px;
    margin-bottom: 48px;

    @include media(M) {
        margin-top: 64px;
        margin-bottom: 64px;
    }

    @include media(ML) {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    &__Wrap {
        @include wrap;

        display: flex;
        flex-direction: column;

        @include media(M) {
            flex-direction: row;
            align-items: center;
        }
    }

    // Image has to be uploaded as 16:9 if the whole image should be visible
    &__Image {
        flex: 0 0 auto;
        position: relative;
        width: 100%;

        @include media(M) {
            width: 50%;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: #{percentage(9/16)};
        }

        img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    &__Content {
        flex: 0 0 auto;
        order: 1; // Image should always be first in mobile
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        padding-top: 24px;
        color: $grey-90;

        @include media(M) {
            order: 0;
            width: 50%;
            padding: 0 32px;

            #{$root}--Right & {
                padding-right: 0;
            }

            #{$root}--Left & {
                padding-left: 0;
            }
        }

        @include media(ML) {
            #{$root}--Right &,
            #{$root}--Left & {
                padding: 0 32px;
            }
        }

        @include media(L) {
            #{$root}--Right &,
            #{$root}--Left & {
                padding: 0 56px;
            }
        }
    }

    &__Title {
        margin: 0;
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 1.33;

        @include media(ML) {
            font-size: 3.6rem;
        }
    }

    &__Text {
        margin-top: 16px;
        font-size: 1.8rem;
        font-weight: normal;
        line-height: 1.56;
    }

    &__Button {
        #{$root} & {
            margin-top: 32px;
        }
    }
}
